.pay-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qrcode {
    margin-top: .69rem;
    margin-bottom: .34rem;
    width: 3.50rem;
    height: 3.50rem;
}
.qrcode img {
    width: 3.50rem;
    height: 3.50rem;
}
.tip1 {
    font-size: .43rem;
    color: black;
    font-weight: bold;
    margin-bottom: .18rem;
    text-align: center;
}
.tip2 {
    font-size: .34rem;
    color: #949494;
    font-weight: bold;
    margin-bottom: .78rem;
}
.upload-btn {
    width: 5.00rem;
    height: 3.39rem;
    background: url('/src/images/Order/upload.png') no-repeat;
    background-size: 100%;
}
.upload-pre {
    width: 5.00rem;
    height: 3.39rem;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: .30rem; 
}
.submit-btn {
    width: 6.12rem;
    height: 1.00rem;
    line-height: 1.00rem;
    text-align: center;
    font-size: .33rem;
    color: white;
    font-weight: bold;
    background: #8F1C22;
    border: .02rem solid #8F1C22;
    box-shadow: 0rem .04rem .05rem rgba(143, 28, 34, 0.32);
    border-radius: 1.21rem;
    margin-top: 1.23rem;
}
.submit-btn.disabled {
    background: #bebbbb;
    border: none;
}
.success-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.suc-img {
    width: 1.05rem;
    height: 1.05rem;
    margin-top: 2.74rem;
    margin-bottom: 1.45rem;
}
.oper-btn {
    width: 6.12rem;
    height: 1.00rem;
    line-height: 1.00rem;
    text-align: center;
    font-size: .33rem;
    font-weight: bold;
    color: white;
    background: #8F1C22;
    border: .02rem solid #8F1C22;
    box-shadow: 0 .04rem .05rem rgba(143, 28, 34, 0.32);
    border-radius: 1.21rem;
    margin-top: 1.16rem;
}