.order-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #F1F1F1;
}
.tab-bar {
    width: 100%;
    height: 1.00rem;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 .20rem;
    justify-content: space-around;
}
.tab-bar .tab-item {
    color: black;
    font-size: .31rem;
    font-weight: 500;
    height: 1.00rem;
    line-height: .95rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tab-bar .tab-item.selected {
    font-weight: bold;
    color: #8F1C22;
    position: relative;
}
.tab-bar .tab-item-decoration {
    width: .43rem;
    height: .05rem;
    background: #8F1C22;
}
.list-inner {
    width: 100%;
    height: calc(100vh - 1.00rem);
    padding: .27rem .20rem;
    overflow-y: scroll;
}
.list-item {
    width: 100%;
    background: white;
    border-radius: .27rem;
    padding: .25rem .27rem .25rem .43rem;
    overflow: hidden;
    margin-bottom: 27rem;
}
.list-item .title {
    color: black;
    font-size: .29rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-bottom: .42rem;
}
.list-item .title >span {
    color: #8F1C22;
}
.ware-item {
    width: 100%;
    display: flex;
    margin: .22rem 0;
    overflow: hidden;
}
.ware-picture {
    width: 1.27rem;
    height: 1.27rem;
    border-radius: .09rem;
    overflow: hidden;
}
.ware-picture img {
    width: 1.27rem;
    height: 1.27rem;
}
.ware-title {
    width: 3.20rem;
    font-size: .27rem;
    color: black;
    font-weight: bold;
    overflow: hidden;
    margin-left: .16rem;
}
.ware-title >span {
    display: block;
    font-size: .22rem;
    color: #A8A8A8;
    font-weight: normal;
}
.ware-price {
    width: 1.91rem;
    color: #A8A8A8;
    font-size: .22rem;
    text-align: right;
}
.ware-price .p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ware-price .p >span {
    font-size: .29rem;
    color: #8F1C22;
    margin-right: .10rem;
}
.list-item .oper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.list-item .oper .total {
    color: #A8A8A8;
    font-size: .25rem;
    display: flex;
    align-items: center;
}
.list-item .oper .total >span {
    color: #8F1C22;
    font-size: .42rem;
    font-weight: 500;
    margin-left: .10rem;
}
.list-item .oper .btn {
    margin-left: .24rem;
    font-size: .33rem;
    color: white;
    width: 2.14rem;
    height: .74rem;
    line-height: .74rem;
    text-align: center;
    font-weight: bold;
    border: .02rem solid #8F1C22;
    background: #8F1C22;
    box-shadow: 0 .04rem .05rem rgba(143, 28, 34, 0.32); 
    border-radius: 1.21rem;   
}
.list-empty {
    width: 100%;
    font-size: .24rem;
    color: rgba(0, 0, 0, 0.57);
    text-align: center;
    padding: 1.20rem 0;
}