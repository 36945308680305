.confirm-wrap {
    width: 100%;
    height: 100vh;
    background: #F1F1F1;
    position: relative;
}
.confirm-wrap-inner {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: .80rem;
}
.address-wrap {
    width: 7.10rem;
    height: 1.67rem;
    background: white;
    border-radius: .27rem;
    margin: .20rem auto;
    padding: .38rem .43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.address-wrap >p {
    color: black;
    font-size: .29rem;
    font-weight: bold;
    margin: 0;
}
.address-wrap >span {
    font-size: .24rem;
    color: #979797;
}
.detail-wrap {
    width: 7.10rem;
    background: white;
    border-radius: .27rem;
    padding: .31rem;
    margin: 0 auto;
}
.detail-title {
    display: flex;
    padding: 0 .13rem;
    justify-content: space-between;
    border-bottom: .02rem solid #70707010;
    padding-bottom: .20rem;
}
.detail-title >span {
    font-size: .29rem;
    color: black;
    font-weight: bold;
}

.order-amount {
    width: 7.10rem;
    display: flex;
    padding: .31rem .43rem;
    justify-content: space-between;
    background: white;
    border-radius: .27rem;
    margin: .20rem auto;
}
.order-amount >span {
    font-size: .29rem;
    color: black;
    font-weight: bold;
}
.cart-content .list-item {
    height: 1.27rem;
    width: 100%;
    margin: .18rem 0;
    display: flex;
    align-items: center;
}
.cart-content .list-item .pic {
    width: 1.32rem;
    height: 1.32rem;
    border-radius: .09rem;
    overflow: hidden;
}
.cart-content .list-item .pic img {
    width: 1.32rem;
    height: 1.32rem;
}
.cart-content .list-item .info {
    margin-left: .16rem;
    width: 3.49rem;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cart-content .list-item .info .title {
    color: black;
    font-size: .27rem;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .sku-title {
    color: #ACACAC;
    font-size: .22rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .price {
    font-size: .29rem;
    color: #FF0000;
    display: flex;
    align-items: center;
}
.cart-content .list-item .info .price >span {
    font-size: .22rem;
    color: #A8A8A8;
    margin-left: .13rem;
}
.cart-content .list-item .count {
    width: calc(100% - 5.10rem);
    margin-top: .80rem;
    text-align: right;
}
.order-bar {
    width: 100%;
    height: 1.03rem;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: .31rem;
    padding-right: .29rem;
    position: absolute;
    bottom: 0;
}
.order-bar .info {
    font-size: .25rem;
    color: #A8A8A8;
    display: flex;
    align-items: center;
}
.order-bar .info >span {
    font-size: .42rem;
    color: #8F1C22;
    font-weight: bold;
}
.order-bar .pay-btn {
    width: 2.17rem;
    height: .80rem;
    line-height: .80rem;
    text-align: center;
    font-weight: bold;
    color: white;
    background: #8F1C22;
    border-radius: .40rem;
    font-size: .27rem;
}
