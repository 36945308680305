.footer-nav {
    width: 100%;
    height: 1.16rem;
    background: white;
    border-top: 1px solid #E8E8E8;
    display: flex;
    justify-content: space-around;
    z-index: 299;
}
.footer-nav .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .34rem;
}
.footer-nav .nav-item img {
    width: .35rem;
    height: .35rem;
}
.footer-nav .nav-item span {
    color: #C8C8C8;
    font-size: .24rem;
    margin-top: .02rem;
}
.footer-nav .nav-item.selected span {
    color: #8F1C22;
}