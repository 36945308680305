.detail-wrap {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: #F1F1F1;
    position: relative;
}
.top-wrap {
    width: 7.08rem;
    height: 1.58rem;
    box-shadow: 0 0 .72rem rgba(225, 225, 225, 0.52);
    border-radius: .27rem;
    color: #8F1C22;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .33rem;
    margin: .22rem auto;
    font-weight: bold;
    background: white;
}
.order-item {
    width: 7.10rem;
    background: white;
    border-radius: .27rem;
    padding: .33rem .40rem;
    margin: 0 auto;
}

.ware-item {
    width: 100%;
    display: flex;
    margin: .22rem 0;
    overflow: hidden;
}
.ware-picture {
    width: 1.27rem;
    height: 1.27rem;
    border-radius: .09rem;
    overflow: hidden;
}
.ware-picture img {
    width: 1.27rem;
    height: 1.27rem;
}
.ware-title {
    width: 3.20rem;
    font-size: .27rem;
    color: black;
    font-weight: bold;
    overflow: hidden;
    margin-left: .16rem;
}
.ware-title >span {
    display: block;
    font-size: .22rem;
    color: #A8A8A8;
    font-weight: normal;
}
.ware-price {
    width: 1.91rem;
    color: #A8A8A8;
    font-size: .22rem;
    text-align: right;
}
.ware-price .p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ware-price .p >span {
    font-size: .29rem;
    color: #8F1C22;
    margin-right: .10rem;
}
.order-item .oper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.order-item .oper .total {
    color: #A8A8A8;
    font-size: .25rem;
    display: flex;
    align-items: center;
}
.order-item .oper .total >span {
    color: #8F1C22;
    font-size: .42rem;
    font-weight: 500;
    margin-left: .10rem;
}
.order-item .top-title, .order-info .top-title {
    margin-bottom: .29rem;
    font-size: .33rem;
    color: black;
    font-weight: bold;
}
.order-info {
    width: 7.10rem;
    background: white;
    border-radius: .27rem;
    padding: .33rem .40rem;
    margin: 0 auto;
    margin-top: .33rem;
    padding-bottom: .16rem;
    margin-bottom: 2.21rem;
}
.order-info .top-title {
    margin-bottom: .31rem;
}
.order-info .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .47rem;
}
.order-info .info-item .label {
    font-size: .24rem;
    color: #A8A8A8;
}
.order-info .info-item .content {
    color: black;
    font-size: .25rem;
    display: flex;
    align-items: center;
}
.order-info .copy {
    font-size: .24rem;
    color: black;
    border: .02rem solid #A8A8A8;
    border-radius: .05rem;
    width: .67rem;
    height: .38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .24rem;
}
.oper-btn {
    width: 6.12rem;
    height: 1.00rem;
    text-align: center;
    line-height: 1.00rem;
    color: white;
    background: #8F1C22;
    border-radius: 1.21rem;
    border: .02rem solid #8F1C22;
    box-shadow: 0rem .04rem .05rem rgba(143, 28, 34, 0.32); 
    font-weight: bold;
    position: fixed;  
    left: calc(50% - 3.06rem);
    bottom: .72rem;
    font-size: .33rem;
}