.home-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.search-wrap {
    width: 7.03rem;
    height: .72rem;
    background: #F6F6F6;
    border-radius: .38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .16rem auto;
}
.search-wrap .placeorder {
    width: 100%;
    height: .72rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-wrap .placeorder img {
    width: .28rem;
    height: .28rem;
    margin-right: .18rem;
}
.search-wrap .placeorder span {
    color: #C8C8C8;
    font-size: .24rem;
}
.search-wrap input {
    width: 100%;
    height: .72rem;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: .24rem;
    text-align: center;
    padding: 0 .30rem;
}
.ware-wrap {
    width: 100%;
    border-top: 1px solid #F0EFF5;
    height: calc(100vh - 2.20rem);
    display: flex;
}
.cate-nav {
    width: 1.76rem;
    height: 100%;
    background: #F7F7F7;
    overflow-y: scroll;
}
.cate-nav .nav-item {
    color: #222222;
    font-size: .24rem;
    text-align: center;
    height: .87rem;
    line-height: .87rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cate-nav .nav-item.selected {
    font-size: .27rem;
    color: #B50029;
    font-weight: 500;
    position: relative;
    background: white;
}
.cate-nav .nav-item.selected::after {
    content: "";
    width: .02rem;
    height: .56rem;
    display: block;
    position: absolute;
    left: 0;
    top: .155rem;
    background: #B50029;
}
.ware-list {
    width: calc(100% - 1.76rem);
    height: 100%;
}
.ware-list .list-top {
    width: 100%;
    height: .87rem;
    display: flex;
    align-items: center;
    padding: 0 .25rem;
}
.ware-list .list-top .cate-name {
    color: black;
    font-size: .29rem;
}
.ware-list .list-inner {
    width: 100%;
    height: calc(100% - .87rem);
    overflow-y: scroll;
    padding: .10rem .29rem;
    padding-bottom: 1.30rem;
}
.ware-list .ware-item {
    width: 100%;
    height: 1.56rem;
    display: flex;
    margin-bottom: .40rem;
}
.ware-list .ware-item:last-of-type {
    margin-bottom: 0;
}
.ware-list .ware-item .picture {
    width: 1.56rem;
    height: 1.56rem;
    box-shadow: 0 0 .54rem rgba(0, 0, 0, 0.06);
    border-radius: .20rem;
    overflow: hidden;
}
.ware-list .ware-item .picture img {
    width: 1.56rem;
    height: 1.56rem;
}
.ware-list .ware-item .info {
    width: calc(100% - 1.85rem);
    height: 100%;
    margin-left: .29rem;
}
.ware-list .ware-item .name {
    font-size: .27rem;
    color: black;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ware-list .ware-item .desc {
    font-size: .20rem;
    color: #A8A8A8;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ware-list .ware-item .option {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .42rem;
}
.ware-list .ware-item .option >span {
    color: #A8A8A8;
    font-size: .20rem;
    display: flex;
    line-height: .47rem;
    white-space: nowrap;
}
.ware-list .ware-item .option >span >i {
    color: #FF0000;
    font-size: .33rem;
    font-style: normal;
    margin-right: .02rem;
}
.ware-list .ware-item .option .select-btn {
    width: 1.01rem;
    height: .43rem;
    background: #8F1C22;
    border-radius: .38rem;
    line-height: .43rem;
    text-align: center;
    color: white;
    font-size: .24rem;
}
.detail-mask {
    width: 100%;
    height: calc(100vh - 1.16rem);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-wrap {
    width: 6.01rem;
    border-radius: .36rem;
    background: white;
    overflow: hidden;
}
.detail-wrap .main-img {
    width: 6.01rem;
    height: 3.39rem;
}
.detail-wrap .main-img img {
    width: 100%;
    height: 100%;
}
.detail-wrap .title {
    font-size: .36rem;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .14rem;
    margin-bottom: .04rem;
    padding: 0 .33rem;
}
.detail-wrap .title >span {
    font-weight: bold;
}
.detail-wrap .price {
    color: #8F1C22;
    font-size: .36rem;
}
.detail-wrap .price i {
    font-size: .27rem;
    font-style: normal;
}
.detail-wrap .desc {
    padding: 0 .33rem;
    font-size: .25rem;
    color: #333333;
    line-height: 36rem;
    margin-bottom: 58rem;
}
.detail-wrap .sku {
    width: 100%;
    padding: .20rem .33rem;
}
.detail-wrap .sku >span {
    font-size: .20rem;
    color: #8F1C22;
    padding: .10rem .30rem;
    border: .02rem solid #dfc9c9;
    margin-right: .30rem;
    border-radius: .15rem;
    display: inline-block;
    margin-bottom: .20rem;
}
.detail-wrap .sku span.selected {
    background: #dfc9c9;
}
.detail-wrap .sku span:last-of-type {
    margin-right: none;
}
.detail-wrap .oper {
    width: 100%;
    display: flex;
    height: .90rem;
}
.detail-wrap .cancel-btn {
    width: 50%;
    height: .90rem;
    font-size: .24rem;
    color: #CECECE;
    background: white;
    text-align: center;
    line-height: .90rem;
    font-weight: 500;
}
.detail-wrap .add-btn {
    width: 50%;
    height: .90rem;
    font-size: .24rem;
    color: white;
    background:#8F1C22;
    text-align: center;
    line-height: .90rem;
    font-weight: 500;
}
.cart-mask {
    width: 100%;
    height: calc(100vh - 1.16rem);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
}
.cart-mask-close {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
}
.cart-content {
    width: 100%;
    overflow: hidden;
    background: white;
    border-radius: .49rem .49rem 0 0;
    padding: .33rem .45rem;
    padding-bottom: 1.30rem;
    transition: margin ease 0.5s;
    margin-bottom: calc(-60vh - 2.00rem);
}
.cart-content.show {
    margin-bottom: 0;
}
.cart-content .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cart-content .top-title {
    color: black;
    font-size: .25rem;
    font-weight: 500;
}
.cart-content .top-title span {
    color: #828282;
    font-size: .22rem;
}
.cart-content .clear {
    font-size: .22rem;
    color: #ACACAC;
}
.cart-content .list-inner {
    max-height: calc(60vh);
    overflow-y: scroll;
    margin-top: .16rem;
}
.cart-content .list-item {
    height: 1.27rem;
    width: 100%;
    margin: .18rem 0;
    display: flex;
    align-items: center;
}
.cart-content .list-item .pic {
    width: 1.32rem;
    height: 1.32rem;
    border-radius: .09rem;
    overflow: hidden;
}
.cart-content .list-item .pic img {
    width: 1.32rem;
    height: 1.32rem;
}
.cart-content .list-item .info {
    margin-left: .16rem;
    width: 3.49rem;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cart-content .list-item .info .title {
    color: black;
    font-size: .27rem;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .sku-title {
    color: #ACACAC;
    font-size: .22rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .price {
    font-size: .29rem;
    color: #FF0000;
    display: flex;
    align-items: center;
}
.cart-content .list-item .info .price >span {
    font-size: .22rem;
    color: #A8A8A8;
    margin-left: .13rem;
}
.cart-content .list-item .oper {
    margin-top: .80rem;
}
.number-btn {
    width: 1.55rem;
    height: .45rem;
    display: flex;
    align-items: center;
}
.number-btn .btn {
    width: .43rem;
    height: .43rem;
    border-radius: 50%;
    color: #414141;
    border: .02rem solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .35rem;
    line-height: 0;
}
.number-btn >span {
    font-size: .38rem;
    color: #414141;
    width: .69rem;
    display: block;
    line-height: .43rem;
    text-align: center;
}
.cart-bar {
    position: absolute;
    bottom: 1.36rem;
    width: 6.61rem;
    height: .96rem;
    background: #4B4B4B;
    box-shadow: 0 .05rem .11rem rgba(0, 0, 0, 0.1);    
    border-radius: .89rem;
    left: calc(50% - 3.30rem);
    z-index: 100;
    padding-left: .51rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cart-bar .cart-info {
    display: flex;
    align-items: center;
    position: relative;
}
.cart-bar .cart-info img {
    width: .57rem;
    height: .57rem;
    margin-right: .45rem;
}
.cart-bar .cart-info .price {
    font-size: .35rem;
    color: white;
    font-weight: bold;
}
.cart-bar .cart-info .price span {
    font-size: .20rem;
}
.cart-bar .go-order {
    width: 2.01rem;
    height: .96rem;
    line-height: .96rem;
    text-align: center;
    font-size: .33rem;
    color: #A5A5A5;
}
.cart-bar .go-order.has {
    background:#8F1C22;
    border-radius: 0 .89rem .89rem 0;
    color: white;
}
.cart-bar .count {
    position: absolute;
    left: .48rem;
    top: -.09rem;
    width: .31rem;
    height: .31rem;
    text-align: center;
    font-size: .22rem;
    color: white;
    background: #8F1C22;
    display: block;
    line-height: .31rem;
    border-radius: 50%;
}
.empty-ware-list {
    padding: 1.20rem 0;
    font-size: .24rem;
    color: rgba(0, 0, 0, 0.57);
    text-align: center;
}
.empty-cart-list {
    padding: .50rem 0;
    font-size: .24rem;
    color: rgba(0, 0, 0, 0.57);
    text-align: center;
}