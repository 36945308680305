*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
}
a {
  text-decoration: none;
}
html {
  background: white;
  font-size: calc(10000vmin / 750);
}
.toast-mask {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 199;
}
.toast-mask .toast-content {
  padding: .10rem .20rem;
  min-width: 1.50rem;
  background: rgba(0,0,0,0.7);
  color: white;
  font-size: .27rem;
  text-align: center;
  border-radius: .10rem;
}