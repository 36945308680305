.login-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login-wrap .logo {
    width: 2.19rem;
    height: 2.36rem;
    margin-bottom: 1.09rem;
}
.login-wrap .logo img {
    width: 2.19rem;
    height: 2.36rem;
}
.login-wrap .form-input {
    width: 6.12rem;
    height: .98rem;
    background: #EEEEEE;
    border-radius: .36rem;
    margin-bottom: .25rem;
}
.login-wrap .form-input input {
    width: 6.12rem;
    height: .98rem;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0 .31rem;
    font-size: .29rem;
}
.login-wrap .form-submit {
    margin-top: .22rem;
    width: 6.12rem;
    height: 1.00rem;
    background: #8F1C22;
    box-shadow: 0 .04rem .05rem rgba(143, 28, 34, 0.32);
    border-radius: 1.21rem;
    text-align: center;
    line-height: 1.00rem;
    font-weight: 500;
    color: white;
    font-size: .33rem;
}