.my-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.my-inner {
    width: 100%;
    height: calc(100vh - 1.16rem);
    overflow-y: scroll;
}
.top-wrap {
    width: 100%;
    height: 5.87rem;
    background: url(/src/images/My/top.png) no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.top-wrap .head {
    width: 1.77rem;
    height: 1.77rem;
    overflow: hidden;
    border-radius: 50%;
    background: rgba(255,255,255,0.39);
    margin-top: .91rem;
    margin-bottom: .29rem;
}
.top-wrap .head img {
    width: 100%;
    height: 100%;
}
.top-wrap .nickname {
    font-size: .33rem;
    color: white;
    font-weight: bold;
    margin-bottom: .20rem;
}
.top-wrap .usertype {
    padding: .04rem .49rem;
    border-radius: .25rem;
    background: white;
    font-size: .33rem;
    color: #8F1C22;
    text-align: center;
    font-weight: bold;
}
.nav-wrap {
    width: 6.97rem;
    background: white;
    border: .02rem solid #F1F1F1;
    box-shadow: 0 .04rem .91rem rgba(145, 145, 145, 0.13);
    margin: 0 auto;
    margin-top: -1.20rem;
    padding: 0 .62rem;
    border-radius: .42rem;
    padding-right: .31rem;
}
.nav-item {
    width: 100%;
    height: 1.16rem;
    display: flex;
    align-items: center;
    background: url(/src/images/My/arrow.png) no-repeat;
    background-size: .13rem .23rem;
    background-position: center right;
    border-bottom: .02rem solid #70707011;
}
.nav-item:last-of-type {
    border-bottom: none;
}
.nav-item .icon {
    width: .30rem;
    height: .30rem;
    margin-right: .16rem;
}
.nav-item .icon img {
    width: .30rem;
    height: .30rem;
}
.nav-item .title {
    font-size: .33rem;
    color: #292929;
}
.logout {
    width: 6.97rem;
    font-size: .29rem;
    color: #5D5D5D;
    padding: .28rem 0;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #921d25;
    margin: .20rem auto;
}
.logout span {
    color: white;
}