.cart-wrap {
    width: 100%;
    height: 100vh;
}
.cart-wrap-inner {
    width: 100%;
    height: calc(100vh - 1.16rem);
}
.number-btn {
    width: 1.55rem;
    height: .45rem;
    display: flex;
    align-items: center;
}
.number-btn .btn {
    width: .43rem;
    height: .43rem;
    border-radius: 50%;
    color: #414141;
    border: .02rem solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .35rem;
    line-height: 0;
}
.number-btn >span {
    font-size: .38rem;
    color: #414141;
    width: .69rem;
    display: block;
    line-height: .43rem;
    text-align: center;
}
.cart-content {
    width: 100%;
    height: calc(100% - 1.03rem);
    background: #F1F1F1;
    padding: .31rem 0;
    overflow-y: scroll;
}
.cart-content .list-inner {
    width: 7.10rem;
    margin: 0 auto;
    background: white;
    border-radius: .27rem;
}
.cart-content .list-item {
    height: 1.87rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: .34rem 0;
    border-bottom: .02rem solid #eae4e4;
}
.cart-content .list-item:last-of-type {
    border-bottom: none;
}

.cart-content .list-item .check {
    width: .36rem;
    height: .36rem;
    margin-left: .34rem;
    margin-right: .24rem;
    border: .02rem solid #b9b3b362;
    border-radius: 50%;
}
.cart-content .list-item .checked {
    width: .36rem;
    height: .36rem;
    margin-left: .34rem;
    margin-right: .24rem;
    background: url('/src/images/Order/success.png') no-repeat;
    background-size: 100%;
}
.cart-content .list-item .pic {
    width: 1.32rem;
    height: 1.32rem;
    border-radius: .09rem;
    overflow: hidden;
}
.cart-content .list-item .pic img {
    width: 1.32rem;
    height: 1.32rem;
}
.cart-content .list-item .info {
    margin-left: .16rem;
    width: 2.80rem;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cart-content .list-item .info .title {
    color: black;
    font-size: .27rem;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .sku-title {
    color: #ACACAC;
    font-size: .22rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cart-content .list-item .info .price {
    font-size: .29rem;
    color: #FF0000;
    display: flex;
    align-items: center;
}
.cart-content .list-item .info .price >span {
    font-size: .22rem;
    color: #A8A8A8;
    margin-left: .13rem;
}
.cart-content .list-item .oper {
    margin-top: .80rem;
    margin-right: .34rem;
}
.oper-wrap {
    width: 100%;
    height: 1.03rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.oper-wrap .left {
    display: flex;
    align-items: center;
}
.oper-wrap .check {
    width: .36rem;
    height: .36rem;
    margin-left: .54rem;
    margin-right: .20rem;
    border: .02rem solid #b9b3b362;
    border-radius: 50%;
}
.oper-wrap .checked {
    width: .36rem;
    height: .36rem;
    margin-left: .54rem;
    margin-right: .20rem;
    background: url('/src/images/Order/success.png') no-repeat;
    background-size: 100%;
}
.oper-wrap .all {
    font-size: .29rem;
    color: black;
    font-weight: bold;
}
.oper-wrap .sum {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.oper-wrap .sum .total {
    color: #A8A8A8;
    font-size: .25rem;
}
.oper-wrap .sum .total >span {
    font-size: .42rem;
    color: #8F1C22;
    font-weight: 500;
}
.oper-wrap .sum .order-btn {
    background: #8F1C22;
    width: 2.17rem;
    height: .80rem;
    border-radius: .40rem;
    line-height: .80rem;
    text-align: center;
    font-weight: bold;
    font-size: .27rem;
    color: white;
    margin-left: .22rem;
    margin-right: .29rem;
}